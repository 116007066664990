import { GetServerSidePropsContext } from 'next';

import { initializeApollo } from '@/libs/apollo-client';
import { RootPageQueryDocument } from '@/generated/graphql';

const RootPage = () => {
  return null;
};

RootPage.auth = true;
RootPage.subscription = false;
RootPage.appVersionCheck = true;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  try {
    const apolloClient = initializeApollo({ context });
    const { data } = await apolloClient.query({
      query: RootPageQueryDocument,
    });

    const isSubscriptionAvailable = data?.subscription?.isAvailable;

    const guardianRelationships = data?.relationships;
    const firstConnectedChild =
      guardianRelationships?.[0]?.childAccount?.id ?? '';

    return {
      redirect: {
        destination: isSubscriptionAvailable
          ? '/home'
          : `/demo${
              (guardianRelationships.length ?? 0) !== 0
                ? `?childId=${firstConnectedChild}`
                : ''
            }`,
        permanent: true,
      },
      props: {
        initialApolloState: apolloClient.cache.extract(),
      },
    };
  } catch (error: any) {
    return { props: { error: error.toString() } };
  }
}

export default RootPage;
